





























































import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BFormGroup,
  BFormRadio
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeModificationModel } from "@/api/models/creneaux/creneau";
import CreateDemandeModificationModal from "./CreateDemandeModificationModal.vue"
import { EtatDemandeModification } from "@/api/models/enums/etatDemandeModification";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormGroup,
    BFormRadio,
    CreateDemandeModificationModal,
  },
})
export default class ListDemandesModifications extends Vue {
  etatDemandeModification = EtatDemandeModification

  isAddNewDemandeModificationSidebarActive = false;
  isUpdateDemandeModificationSidebarActive = false;
  selectedDemandeModificationId = "";
  searchQuery = "";
  timeout: any = null;

  tableColumns = [{ key: "libelle", sortable: true, label: "libellé" }, { key: "actions" }];
  listDemandesModifications: DemandeModificationModel[] = []
  listEtats: string[] = []

  getKeyFromValueEtat(value: string) {
    const indexOfS = Object.values(this.etatDemandeModification).indexOf(value as unknown as EtatDemandeModification);

    return Object.keys(EtatDemandeModification)[indexOfS];
  }

  async created() {
    await this.loadListDemandesModifications();
    await this.getEtats()
  }

  async getEtats() {
    await this.$http.myressif.parametres
      .getByNom("plannings_demande_modification_etat")
      .then((res: any) => {
        if (res.valeur) {
          this.listEtats = JSON.parse(res.valeur);
        }
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  // selectDemandeModification(demandeModificationId: string) {
  //   this.$router.push({
  //     name: "details-demandeModification",
  //     params: { id: demandeModificationId },
  //   });
  // }

  editDemandeModification(demandeModificationId: any) {
    this.selectedDemandeModificationId = demandeModificationId;
    this.isUpdateDemandeModificationSidebarActive = true;
  }

  async patchEtatDemandeModificationPlanning(demandeModification: DemandeModificationModel, newEtat: any) {
    await this.$http.myressif.utilisateurs
      .patchDemandeModification(demandeModification.utilisateurId, demandeModification.id, newEtat)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Modification de l'état d'une demande de modification",
            text: "Modification effectuée avec succès",
          });

          this.$store.dispatch('demandes/fetchDemandes')
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListDemandesModifications();
      });
  }

  async loadListDemandesModifications() {
    await this.$http.myressif.utilisateurs
      .listDemandesModifications(this.$store.state.user.user.uid)
      .then((response: DemandeModificationModel[]) => {
        this.listDemandesModifications = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce type de créneau ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.typesCreneau
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un type de créneau",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.loadListDemandesModifications();
      });
  }
}
